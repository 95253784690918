<!--  -->
<template>
  <div class='xuexi round bj-col-white pad' v-loading="loading">
    <div class="heard">
      <h3>{{year}}执业药师继续教育课程</h3>
    </div>
    <div class="nav">
      <div class="tabs">
        <div class="tab">
          <img src="../assets/img/jiaofei.png" alt="" v-if="userInfo.xuanke == 1">
          <img src="../assets/img/jiaofei2.png" alt="" v-else>
          <span>选课</span>
          <i class="el-icon-success icon" v-if="userInfo.xuanke == 1"></i>
        </div>
        <div class="line" :class="{'ban': false}"></div>
        <div class="tab">
          <img src="../assets/img/xuanke.png" alt="" v-if="userInfo.xuanke == 1">
          <img src="../assets/img/xuanke2.png" alt="" v-else>
          <span>缴费</span>
          <i class="el-icon-success icon" v-if="userInfo.xuanke == 1"></i>
        </div>
        <div class="line" :class="{'ban': true}"></div>
        <div class="tab">
          <img src="../assets/img/xuexi.png" alt="" v-if="userInfo.xuexi == 1">
          <img src="../assets/img/xuexi2.png" alt="" v-else>
          <span>学习</span>
          <i class="el-icon-success icon" v-if="userInfo.xuexi == 1"></i>
        </div>
        <div class="line" :class="{'ban': true}"></div>
        <div class="tab">
          <img src="../assets/img/kaoshi.png" alt="" v-if="userInfo.kaoshi == 1">
          <img src="../assets/img/kaoshi2.png" alt="" v-else>
          <span>考试</span>
          <i class="el-icon-success icon" v-if="userInfo.kaoshi == 1"></i>
        </div>
        <div class="line" :class="{'ban': true}"></div>
        <div class="tab">
          <img src="../assets/img/dayin.png" alt="" v-if="userInfo.xuefen == 1">
          <img src="../assets/img/dayin2.png" alt="" v-else>
          <span>学分打印</span>
          <i class="el-icon-success icon" v-if="userInfo.xuefen == 1"></i>
        </div>
      </div>
      <div class="code">
        <img src="../assets/img/code/study-h5.png" alt="">
        <span>手机学习请扫公众号二维码</span>
      </div>
    </div>
    
    <div class="prompt">
      <h3>温馨提示</h3>
      <div>1、电脑或手机端上学习，每节课程学习完后进行测试</div>
      <div>2、每科不需要一次性看完，中途退出、再重新登录，系统自动保留上一次学习记录。</div>
      <!-- <div>3、电脑学习需安装或自带摄像头，如没有请扫右上角二维码进入学习。</div> -->
    </div>

    <template></template>

    <template>
      <div class="table">
        <div @click="type = 0">
          <h4 :style="{color: type == 0 ? '#06B487' : ''}">{{info.all || 0}}</h4>
          <div :style="{color: type == 0 ? '#06B487' : ''}">全部课程</div>
        </div>
        <div @click="type = 1">
          <h4 :style="{color: type == 1 ? '#06B487' : ''}">{{info.over || 0}}</h4>
          <div :style="{color: type == 1 ? '#06B487' : ''}">已完成</div>
        </div>
        <div @click="type = 2">
          <h4 :style="{color: type == 2 ? '#06B487' : ''}">{{info.nover || 0}}</h4>
          <div :style="{color: type == 2 ? '#06B487' : ''}">未完成</div>
        </div>
      </div>
      
      <div class="list">
        <kc-list :type="type"></kc-list>
      </div>
    </template>
    

    <el-dialog
      :visible.sync="hint"
      width="30%"
      top="12vh"
      center>
      <div class="hint">
        <img src="../assets/img/chenggong.png" alt="">
        <p>
          恭喜您！所有课程已学完。 
          <br/>
          请等待1个工作日，学习档案审核通过即可参加考试
        </p>
      </div>
      <span slot="footer"></span>
    </el-dialog>

  </div>
</template>

<script>
import Api from '../api/study'
import KcList from './components/KcList.vue'

export default {
  components: {
    KcList,
  },
  data() {
    return {
      hint: false,
      info: {},
      type: 0,
      loading: true,
      year: '',
      userInfo: {}
    };
  },
  computed: {},
  watch: {
    
  },
  created() {
  
  },
  mounted() {
    setTimeout(() => {
      this.userInfo = localStorage.getItem("userInfo")
      this.userInfo = JSON.parse(this.userInfo)
      this.year = localStorage.getItem("year")
      this.courseStatus()
    }, 200)
  },
  //方法集合
  methods: {
    courseStatus () {
      Api.courseStatus().then(res => {
        this.loading = false
        if (res.data.code == 1) {
          this.info = res.data.data
        } else {
          this.$message.error("课程学习进度获取失败")
        }
      })
    },
  },
}
</script>

<style lang='less' scoped>
.xuexi {
  .heard {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .nav {
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0 10px;
    .tabs {
      width: 70%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .tab {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        .icon {
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(20%, -20%);
          color: @default;
        }
      }
      .line {
        width: 50px;
        height: 2px;
        background-color: @default;
      }
      .ban {
        background-color: #E5E5E5;
      }
    }
    .code {
      padding: 30px 0 30px 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-left: 1px solid #D8D8D8;
      img {
        width: 120px;
      }
    }
  }
  .prompt {
    margin-bottom: 30px;
    > div {
      margin-top: 10px;
      span {
        color: red;
      }
    }
  }
  .table {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 95%;
    background-color: #EDF6FF;
    padding: 15px;
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      > div {
        color: #999999;
        margin-top: 10px;
      }
    }
  }
  .list {
    width: 96%;
    margin: 20px 0;
  }

  > .hint {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 80px;
    }
    p {
      margin-top: 30px;
      text-align: center;
      color: #000000;
      padding: 0 30px;
    }
  }
}
</style>