<!--  -->
<template>
  <div class='kc-list'>
    <div v-for="(item, index) in kc_list" :key="index">
      <img :src="item.image" alt="">
      <div class="content">
        <div class="nav">
          <h4>{{item.keshi_name}}</h4>
          <el-tag size="mini" v-if="item.finish == '未完成' || item.jindu == 99">未完成</el-tag>
          <el-tag size="mini" type="warning" v-if="item.finish == '未开始'">{{item.finish}}</el-tag>
          <el-tag size="mini" type="success" v-if="item.finish == '已完成' && item.jindu == 100">{{item.finish}}</el-tag>
        </div>
        <span>类别：{{item.course_name}}</span>
        <span>学时：{{item.shichang | setTime}}分钟</span>
        <span>时间：{{item.add_time}}</span>
        <div class="plan">
          <span>进度：{{item.jindu || 0}}%</span>
          <el-progress :percentage="item.jindu || 0" color="#06B487" :show-text="false" style="width: 200px" :stroke-width="10"></el-progress>
        </div>
      </div>
      <div class="btn">
        <el-button type="success" plain round size="mini" @click="handleSkip(item.keshi_id)">随堂练习</el-button>
        <el-button type="warning" plain round size="mini" @click="handleStudy(index)" v-if="item.yunxu == 1">{{setType(item)}}</el-button>
        <el-button type="info" plain round size="mini" disabled v-else>开始学习</el-button>
        <el-dropdown>
          <el-button type="primary" plain round size="mini" style="margin-left: 10px;">
            更多<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-if="item.comment_status == 0">
              <div @click="handleComment(item.id)">学员评价</div>
            </el-dropdown-item>
            <el-dropdown-item v-else :disabled="true">
              <div>已评价</div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div @click="handleSolve(item.id)">线上答疑</div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="hint">暂无数据</div>
    <video-view v-model="zhuapai" type="sphy" :id="vid" @handlePass="handlePass"></video-view>

    <upload-view v-model="upload" @handlePass="handlePass"></upload-view>

    <comment-view v-if="commentVisible" @close="commentVisible = false" @submit="getCourseList()" ref="comment"></comment-view>

    <div class="video-photo-shell" v-if="applet_show" @click.stop="applet_show=false">
      <div class="camera_outer">
        <div class="title">电脑需安装或自带摄像头，若没有您可以扫码关注公众号进行学习</div>
        <img src="../../assets/img/code/study-h5.png" alt="" class="applet-img">
      </div>
    </div>

    <el-dialog
      title="请下载谷歌浏览器观看视频"
      :visible.sync="dialogVisible"
      width="30%">
      <div @click="handleOpen()" class="upload">
        <img src="../../assets/img/guge.png" alt="">
        <div>电脑学习：<span>下载谷歌浏览器</span></div>
      </div>
    </el-dialog>

    <el-dialog
      title="线上提问"
      @close="visible = false;formData.remark = ''"
      :visible.sync="cmtVisible">
      <div class="comment-view">
        <el-form ref="form" :model="formData" label-width="90px" :rules="rules">
          <el-form-item label="问题：" prop="remark">
            <el-input type="textarea" :rows="4" v-model="formData.remark"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" round @click="handleSubmit">提交问题</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Api from '../../api/study'
import VideoView from './video/index.vue'
import UploadView from './upload/index.vue'
import CommentView from "./kc-action/comment-view";

export default {
  components: {
    VideoView,
    UploadView,
    CommentView
  },
  props: {
    type: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      kc_list: [],
      zhuapai: false,
      vid: '',
      applet_show: false,
      idx: '',
      upload: false,
      dialogVisible: false,
      commentVisible: false,
      cmtVisible: false,
      formData: {
        id: '',
        remark: ''
      },
      rules: {
        remark: [
          { required: true, message: '请输入评价', trigger: 'blur' },
        ],
      }
    };
  },
  filters: {
    setTime (value) {
      return Math.round(value/60)
    }
  },
  computed: {},
  watch: {
    type () {
      this.getCourseList()
    }
  },
  created() {
    this.getCourseList()
  },
  mounted() {
    
  },
  //方法集合
  methods: {
    handleOpen () {
      window.open('https://www.google.cn/chrome/')
    },
    setType (item) {
      if (item.status_ks == '2') {
        return '未通过考核，请重考'
      }
      if (item.is_ks == 0 && item.finish == '已完成') {
        return '开始考试'
      }
      if (item.is_ks == '1') {
        return '回看'
      }
      return '开始学习'
    },
    handleSkip (id) {
      this.$router.push({name: 'Lianxi', query: {id: id}})
    },
    getCourseList () {
      Api.getCourseList({
        type: this.type,
      }).then(res => {
        if (res.status == 200) {
          this.kc_list = res.data
          if (this.kc_list.length == 0 && this.type == 0) {
            this.$router.replace({name: 'Gouke'})
          }
        } else {
          this.$message.error('课程列表获取失败');
        }
      })
    },
    handleStudy (i) {
      // this.$confirm('按考核统一要求，每节课程必须有本人抓拍记录，抓拍时请摄像头对准本人脸部，否则，审核以未学完处理，不能参加统一考核', '重要提示', {
      //   confirmButtonText: '知道了',
      //   showCancelButton: false,
      //   type: 'warning',
      //   center: true
      // }).then(() => {
        
      // }).catch(()=>{})
      Api.getAdmin({
        vid: this.kc_list[i].id
      }).then(res => {
        if (res.data.state == 0) {
          this.$message.warning('不可同时播放多个视频!')
          return;
        }
        // if (res.data.check_tx == 0) {
        //   if ((this.kc_list[i].is_ks == 0 || this.kc_list[i].status_ks == 2) && this.kc_list[i].finish == '已完成') {
        //     this.getExamRoot(res.data.vid)
        //     return
        //   }
        //   if (document.documentMode && this.kc_list[i].is_ks != '1') {
        //     this.dialogVisible = true
        //     return;
        //   }
        //   this.$router.push({name: 'Video', query: {id: res.data.vid}})
        //   return;
        // }

        if (res.data.head_img == '无') {
          if (document.documentMode && this.kc_list[i].is_ks != '1') {
            this.dialogVisible = true
            return;
          }
          this.$message.info('请上传正确格式的人物头像!')
          navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
          if (navigator.getUserMedia) {
            let that = this
            navigator.getUserMedia(
              {video: true,audio: false,},
              function onSuccess(stream) {
                that.upload = true
                let tracks = stream.getTracks();
                tracks.forEach(track => {
                  track.stop()
                })
              },
              function onError() {
                that.$router.push({name: 'User'})
              }
            )
          } else {
            this.$router.push({name: 'User'})
          }
        } else {
          if (res.data.is_one_study == 0 || res.data.check_tx == 0) {
            if ((this.kc_list[i].is_ks == 0 || this.kc_list[i].status_ks == 2) && this.kc_list[i].finish == '已完成') {
              this.getExamRoot(res.data.vid)
              return
            }
            if (document.documentMode && this.kc_list[i].is_ks != '1') {
              this.dialogVisible = true
              return;
            }
            this.$router.push({name: 'Video', query: {id: res.data.vid}})
          } else {
            if (document.documentMode && this.kc_list[i].is_ks != '1') {
              this.dialogVisible = true
              return;
            }
            navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
            if (navigator.getUserMedia) {
              let that = this
              navigator.getUserMedia(
                {video: true,audio: false,},
                function onSuccess(stream) {
                  that.vid = res.data.vid
                  that.zhuapai = true
                  that.idx = i
                  let tracks = stream.getTracks();
                  tracks.forEach(track => {
                    track.stop()
                  })
                },
                function onError() {
                  that.applet_show = true
                }
              )
            } else {
              this.applet_show = true
            }
          }
        }
      })
    },
    handlePass () {
      this.zhuapai = false
      if (this.kc_list[this.idx].is_ks == 0 && this.kc_list[this.idx].finish == '已完成') {
        this.getExamRoot(this.vid)
        return
      }
      this.$router.push({name: 'Video', query: {id: this.vid}})
    },
    getExamRoot (id) {
      Api.getExamRoot({
        vid: id
      }).then(res => {
        if (res.data.code == 1) {
          this.$router.push({name: 'Exam', query: {id: res.data.exam_id}})
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    handleComment (id) {
      this.commentVisible = true
      this.$nextTick(() => {
        this.$refs.comment.init({id})
      })
      
    },
    handleSolve (id) {
      Api.getSolveList({
        vid: id,
        page: 1,
        page_size: 10
      }).then(res => {
        if (res.data.code == 1) {
          if (res.data.data.cnt > 0) {
            this.$router.push({name: 'Solve', query: {id}})
          } else {
            this.formData.id = id
            this.cmtVisible = true
          }
        } else {
          this.$message.error('答疑信息获取失败');
        }
      })
    },
    handleSubmit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          Api.solveFun({
            vid: this.formData.id,
            content: this.formData.remark,
          }).then(res => {
            if (res.data.code == 1) {
              this.cmtVisible = false
              this.$message.success('提交成功');
              // this.$router.push({name: 'Solve', query: {id: this.formData.id}})
            } else {
              this.$message.error('操作失败');
            }
          })
        } else {
          return false;
        }
      })
    }
  },
}
</script>

<style lang='less' scoped>
.kc-list {
  max-height: 800px;
  overflow-y: auto;
  padding-right: 20px;
  &::-webkit-scrollbar{
    width:5px;
    height:5px;
    /**/
  }
  &::-webkit-scrollbar-track{
    background: rgb(239, 239, 239);
    border-radius:2px;
  }
  &::-webkit-scrollbar-thumb{
    background: #bfbfbf;
    border-radius:10px;
  }
  &::-webkit-scrollbar-thumb:hover{
    background: #333;
  }
  &::-webkit-scrollbar-corner{
    background: #179a16;
  }
  > div {
    display: flex;
    justify-content: space-between;
    padding: 30px 0;
    border-bottom: 1px solid #d8d8d8;
    > img {
      max-height: 150px;
    }
    .content {
      flex: 1;
      display: flex;
      margin: 0 20px;
      flex-direction: column;
      .nav {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        h4 {
          margin-right: 10px;
        }
      }
      > span {
        margin-bottom: 10px;
        color: #999;
        font-size: 14px;
      }
      .plan {
        display: flex;
        align-items: center;
        span {
          margin-right: 20px;
        }
      }
    }
    .btn {
      display: flex;
      align-items: flex-end;
    }
  }
  > .hint {
    width: 100%;
    display: block;
    border: 0;
    color: #999;
    text-align: center;
  }
  .video-photo-shell {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 99;
    .camera_outer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      background-color: #fff;
      border: 1px solid #ccc;
      position: relative;
      .applet-img {
        width: 300px;
        height: 300px;
        margin: 20px auto;
      }
      .title {
        padding: 20px 30px;
        width: 100%;
        font-weight: 600;
        border-bottom: 1px solid #eee;
        font-size: 20px;
      }
    }
  }

  .upload {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    > img {
      width: 80px;
      height: 80px;
    }
    > div {
      margin-left: 5px;
      font-size: 18px;
      font-weight: bold;
      span {
        font-size: 18px;
        font-weight: bold;
        color: red;
      }
    }
  }
}

/deep/ .el-dialog {
  height: fit-content;
}
.comment-view {
  width: 80%;
  /deep/ .el-form-item {
    margin-bottom: 20px;
  }
}
.dialog-footer {
  display: flex;
  justify-content: center;
}
</style>