<!--  -->
<template>
  <el-dialog
    title="课程评价"
    @close="onClose"
    :visible.sync="visible">
    <div class="comment-view">
      <el-form ref="form" :model="formData" label-width="90px" :rules="rules">
        <el-form-item label="满意度：" prop="grade">
          <el-select v-model="formData.grade">
            <el-option label="非常满意" :value="1"></el-option>
            <el-option label="基本满意" :value="2"></el-option>
            <el-option label="一般满意" :value="3"></el-option>
            <el-option label="不太满意" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="评价：" prop="remark">
          <el-input type="textarea" :rows="4" v-model="formData.remark"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" round @click="handleSubmit">提交评价</el-button>
    </div>
  </el-dialog>
</template>

<script>
import Api from '../../../api/study'

export default {
  components: {},
  data() {
    return {
      visible: false,
      formData: {
        id: '',
        grade: '',
        remark: ''
      },
      rules: {
        grade: [
          { required: true, message: '请选择满意度', trigger: 'change' },
        ],
        remark: [
          { required: true, message: '请输入评价', trigger: 'blur' },
        ],
      }
    };
  },
  computed: {},
  watch: {},
  created() {
    
  },
  mounted() {
    
  },
  //方法集合
  methods: {
    init (options) {
      this.visible = true
      this.formData.id = options.id
    },
    handleSubmit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          Api.commentFun({
            vid: this.formData.id,
            comment_score: this.formData.grade,
            comment_content: this.formData.remark,
          }).then(res => {
            if (res.status == 200) {
              this.visible = false
              this.$message.success('提交成功');
              this.$emit('submit')
            } else {
              this.$message.error('操作失败');
            }
          })
        } else {
          return false;
        }
      })
    },
    onClose () {
      this.$emit('close')
    }
  },
}
</script>

<style lang='less' scoped>
/deep/ .el-dialog {
  height: fit-content;
}
.comment-view {
  width: 80%;
  /deep/ .el-form-item {
    margin-bottom: 20px;
  }
}
.dialog-footer {
  display: flex;
  justify-content: center;
}
</style>