<!--  -->
<template>
  <div class='video-page'>
    <el-dialog
      width="31%"
      top="12vh"
      :visible.sync="show"
      :close-on-click-modal="close"
      :close-on-press-escape="close"
      :show-close="close"
      :destroy-on-close="true"
      @close="onClose"
      center>
      <div v-loading="loading">
        <div class="zhuapai-view">
          <h3>头像上传</h3>
          <p>温馨提示：请将摄像头对准本人脸部。</p>
          <div class="photo">
            <template v-if="!base64">
              <video id="video"></video>
              <canvas id="canvas"></canvas>
            </template>
            <img :src="base64" v-else alt="">
          </div>
        </div>
        <div class="btn">
          <el-button round type="success" plain class="self-button--success" size="medium" style="width: 160px" @click="handlePat" v-if="!base64">点击拍照</el-button>
          <template v-else>
            <el-button round type="success" plain class="self-button--success" size="medium" style="width: 160px" @click="handleInit()">重新拍照</el-button>
            <el-button round type="success" class="self-button--success" size="medium" style="width: 160px" @click="upload">确认上传</el-button>
          </template>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Api from '../../../api/my'

export default {
  components: {},
  model: {
    prop: 'visible',
    event: 'input'
  },
  props: {
    visible: Boolean,
  },
  data() {
    return {
      show: false,
      loading: true,
      close: false,
      base64: '',
      have: true
    };
  },
  filters: {
    
  },
  computed: {},
  watch: {
    visible () {
      this.show = this.visible
      if (this.show) {this.init()}
    },
  },
  created() {
    
  },
  mounted() {
    
  },
  beforeDestroy () {
    var video = document.getElementById('video');
    if (video && video.srcObject) {
      let stream = video.srcObject
      let tracks = stream.getTracks();
      tracks.forEach(track => {
        track.stop()
      })
    }
  },
  //方法集合
  methods: {
    onClose () {
      var video = document.getElementById('video');
      if (video && video.srcObject) {
        let stream = video.srcObject
        let tracks = stream.getTracks();
        tracks.forEach(track => {
          track.stop()
        })
      }
      this.show = false
      this.$emit('input', this.show)
    },
    handleInit () {
      this.base64 = ''
      this.$nextTick(() => {
        this.init()
      })
    },
    init () {
      let that = this
      that.loading = true
      setTimeout(() => {
        navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
        if (navigator.getUserMedia) {
          navigator.getUserMedia(
            {
              video: true,
              audio: false,
            },
            function onSuccess (stream) {
              setTimeout(() => {
                var video = document.getElementById("video");
                video.srcObject = stream;
                video.onloadedmetadata = function () {
                  video.play();
                  that.loading = false
                };
              }, 200)
            },
            function onError () {
              that.have = false
              that.loading = false
            }
          )
        } else {
          that.have = false
          that.loading = false
        }
      }, 200)
    },
    handlePat () {
      if (!this.have) {
        this.$message.error("电脑需安装或自带摄像头，如没有请进入小程序使用手机上传。")
        return;
      }
      this.loading = true
      var canvas = document.getElementById("canvas");
      var video = document.getElementById("video");
      canvas.width = 500;
      canvas.height = 500;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0, 500, 500);
      var base64 = canvas.toDataURL("images/png");
      this.base64 = base64
      this.loading = false
      this.$message.success("拍摄完成！")
    },
    // 考试核验
    upload () {
      Api.uploadUserImg({
        file: this.base64
      }).then(res => {
        if (res.data.code == 1) {
          this.show = false
          this.$emit('handlePass')
          this.$message.success("保存成功")
        } else {
          this.$message.error("修改失败")
        }
      })
    },
    
  },
}
</script>

<style lang='less' scoped>
.video-page {
  .zhuapai-view {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      color: #DD011B;
      margin: 20px 0 40px;
    }
    .photo {
      width: 300px;
      height: 300px;
      background-color: #EDF6FF;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border-radius: 50%;
      overflow: hidden;
      span {
        font-size: 150px;
        font-weight: bold;
        color: @default;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      #video {
        height: 300px;
      }
      #canvas {
        display: none;
        position: absolute;
        z-index: 200;
        width: 100%;
        height: 100%;
        background: #fff;
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
}
</style>